@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #ebebeb;
  color: #353535;
}

h1 {
  font-weight: 900;
  color: #676ed1;
  font-size: 50px;
}

h2 {
  font-weight: 900;
  color: #353535;
}

.moeda-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  color: #fff;
}

.moeda-search {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.moeda-text {
  margin-bottom: 32px;
  text-align: center;
}

.moeda-input {
  padding-left: 16px;
  width: 500px;
  outline: none;
  height: 50px;
  border: 2px solid transparent;
  border-radius: 19px;
  border-color: #676ed1;
  background-color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1);
}

.moeda-input::-webkit-input-placeholder {
  color: rgb(170, 170, 170);
}

.moeda-input::placeholder {
  color: rgb(170, 170, 170);
}

.moeda-container {
  display: flex;
  justify-content: center;
}

.moeda-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #d7d7d7;
  width: 900px;
}

.moeda {
  display: flex;
  align-items: center;
  padding-right: 24px;
  min-width: 300px;
}

.moeda h2 {
  font-size: 16px;
  width: 150px;
}

.moeda img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.moeda-symbol {
  text-transform: uppercase;
}

.moeda-data {
  display: flex;
  text-align: right;
  justify-content: space-between;
  width: 100%;
}

.moeda-price {
  width: 110px;
}

.moeda-volume {
  width: 155px;
}

.moeda-marketcap {
  width: 230px;
}

.moeda-percent {
  width: 100px;
}

.red {
  color: #f00606;
}

.green {
  color: #1146d8;
}

